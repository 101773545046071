<template>
  <div class="component-wrap">
    <div class="banner"></div>
    <div class="header" id="pageTop">
      <h3>{{ $t("practice.answer_record") }}</h3>
      <a-button type="primary" style="margin: -3px 0" @click="back">
        {{ $t("go_back") }}
      </a-button>
    </div>
    <a-spin :spinning="listLoading">
      <div class="content-wrap">
        <template v-if="dataList.length">
          <div class="content">
            <div class="row title">
              <span class="type">{{ $t("practice.topic_type") }}</span>
              <span class="number">{{
                $t("practice.answer_number_answer_number")
              }}</span>
              <span class="time">{{ $t("practice.time") }}</span>
              <span
                class="integral"
                v-if="
                  companyInfo.menu &&
                  companyInfo.menu.indexOf('integral') !== -1
                "
                >{{ $t("practice.integral") }}</span
              >
            </div>
            <div class="row" v-for="(item, index) in dataList" :key="index">
              <span class="type">
                <template v-if="item.practiceType == 1">{{
                  $t("practice.mode.daily")
                }}</template>
                <template v-else-if="item.practiceType == 2">{{
                  $t("practice.mode.interest")
                }}</template>
              </span>
              <span class="number">
                <strong>{{ item.score }}</strong
                >/{{ item.questionNum }}{{ $t("practice.question") }}
              </span>
              <span class="time">
                {{ dateFormat(item.endTime, "YYYY-MM-DD HH:mm") }}
              </span>
              <span
                class="integral"
                v-if="
                  companyInfo.menu &&
                  companyInfo.menu.indexOf('integral') !== -1
                "
              >
                <strong>+{{ item.integral }}</strong>
                <span v-if="item.integralStatus == 2">{{
                  $t("practice.limit_reached")
                }}</span>
              </span>
            </div>
          </div>
          <div class="page-Wrap">
            <a-pagination
              show-quick-jumper
              hideOnSinglePage
              :defaultPageSize="15"
              v-model:current="currentPage"
              :total="pageTotal"
              @change="pageChange"
            >
              <template #itemRender="{ type, originalElement }">
                <a class="page-a" v-if="type === 'prev'">{{ $t("cm_pre") }}</a>
                <a class="page-a" v-else-if="type === 'next'">{{
                  $t("cm_next")
                }}</a>
                <renderVNode v-else :vnode="originalElement"></renderVNode>
              </template>
            </a-pagination>
          </div>
        </template>
        <a-empty
          v-if="!dataList.length && !listLoading"
          :image="require('@/assets/image/no_data_3.png')"
          :image-style="{ height: '186px' }"
          style="padding: 60px 0"
        >
          <template #description>
            <span style="color: #999">{{
              $t("practice.no_practice_records")
            }}</span>
          </template>
        </a-empty>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { reactive, computed, toRefs, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { dateFormat } from "@/utils/tools";
import { recordList } from "@/api/practice";
const renderVNode = (_, { attrs: { vnode } }) => vnode;
export default {
  components: {
    renderVNode,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const store = useStore();
    const companyInfo = computed(() => store.getters.companyInfo);
    const state = reactive({
      dataList: [],
      currentPage: 1,
      pageTotal: 1,
      listLoading: true,
    });

    const back = () => {
      history.go(-1);
    };

    const pageChange = (n) => {
      state.currentPage = n;
      getList(true);
    };

    const getList = (scroll = false) => {
      state.listLoading = true;
      recordList({
        page: state.currentPage,
        pageSize: 15,
        practiceType: [1, 2],
      }).then((res) => {
        state.listLoading = false;
        state.pageTotal = res.data.totals;
        state.dataList = res.data.list || [];
        if (scroll) {
          setTimeout(() => {
            document.getElementById("pageTop").scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }, 0);
        }
      });
    };

    getList();

    return {
      dateFormat,
      companyInfo,
      ...toRefs(state),
      back,
      pageChange,
    };
  },
};
</script>

<style lang="less" scoped>
@import "../index.less";
.component-wrap {
  padding: 0;
}
.banner {
  width: 100%;
  height: 200px;
  background-image: url("~@/assets/image/practice/banner.png");
  background-size: 100% 100%;
  border-radius: 8px 8px 0 0;
}
.header {
  margin: 0 24px;
  padding: 24px 0;
  line-height: 26px;
  .mixinFlex(space-between; center);
  h3 {
    font-size: 18px;
    font-weight: 600;
    color: #333;
    margin: 0;
  }
  & > div {
    .mixinFlex(flex-end; center);
    span {
      font-size: 16px;
      color: @color-theme;
      margin-left: 24px;
      cursor: pointer;
    }
  }
}
.content-wrap {
  padding: 0 24px 24px !important;
  .content {
    .row {
      .mixinFlex(space-between; center);
      font-size: 16px;
      line-height: 26px;
      padding: 16px 0;
      border-bottom: 1px solid #f4f4f4;
      &.title {
        span {
          font-weight: 600;
          color: #333;
        }
      }
      .type {
        width: 20%;
        color: #333;
      }
      .number {
        width: 20%;
        color: #999;
        strong {
          color: #333;
        }
      }
      .time {
        width: 30%;
        color: #333;
      }
      .integral {
        width: 30%;
        color: #999;
        strong {
          color: @color-theme;
        }
      }
    }
  }
}
</style>
